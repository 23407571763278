<template>
  <div class="shipments-page">
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <print-label-button class="float-left" :selected="selected"></print-label-button>
    </div>
    <shipblu-table
     :sst="true"
     orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Address')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Order Type')}}</shipblu-th>
        <shipblu-th>{{$t('Slot Location')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Scheduled Delivery Date')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].addressLine">
            {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages ? data[indextr].packages.length : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].orderType">
            {{data[indextr].is_exchange ? $t('Exchange') : data[indextr].tracking_number[0] == 1 ? $t('Delivery') : $t('Return')}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].slot">
            <span v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }} <br/>
            </span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].scheduledDeliveryDate">
            {{ data[indextr].estimated_date}}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      type: 'Add',
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      tableLoader: false,
      filter: '',
      searchVal: '',
      offset: 0,
      shipment: {},
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      warehousesDic: {},
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadWarehouseOrders()
    },
    '$route.params.warehouseID' () {
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.currentPage = 1
      common.manageLoadWarehouses(this.formatWarehouses, this)
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadWarehouseOrders()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadWarehouseOrders()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadWarehouseOrders()
    },
    loadWarehouseOrders () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `api/v1/delivery-orders/?current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&status=in_transit&${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    PrintLabelButton,
    ShipbluPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
export {common}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
